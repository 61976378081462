import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import UIFormRow from "@/components/UIFormRow.vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { GetRequest as ScheduleGetRequest } from "@/api/schedule-list/request";
import { GetRequest as CouponGetRequest } from "@/api/coupon-list/request";
import ScheduleListGet from "@/store/schedule-list/get";
import CouponListGet from "@/store/coupon-list/get";
import Flash, { ErrorAlert } from "@/store/common/flash";
import { BusinessServiceStorage } from "@/store/business-service-storage";

const INFO_MESSAGE_TEMPLATE = `<p>今月の（お店・スタッフ）のスケジュールです！</p><br/>
<br/><br/><br/><p>定休日は●●日になっております。</p><br/> 
<br/><br/><br/><p>今月のオススメメニューは●●●●です！</p><br/>
<br/><br/><br/><p>ご来店お待ちしております。</p>`;

const VISIT_MESSAGE_TEMPLATE = `<p>前回ご来店いただき、ありがとうございました。</p><br/>
<br/><br/><br/><p>その後（ヘアスタイル・ネイル・お肌の状態等）はいかがでしょうか？</p><br/>
<br/><br/><br/><p>前回ご来店から●●日が経過しておりますので、またのご来店をお待ちしております！</p><br/>
<br/><br/><br/><br/><br/>
<p>ヘア：これからの季節に合わせて（ヘアカラー・ヘッドスパ・トリートメント）なんていかがでしょうか？</p><br/>
<br/><br/><br/>
<p>ネイル：新しいネイルデザインできました！流行の●●●●ネイルや●●●●ネイルなんていかがでしょうか？</p><br/>
<br/><br/><br/>
<p>エステ・リラク：継続的な施術と自宅でのケアが更に効果を高めてくれるので、次回は●●●●コースもオススメです！また、自宅でのケアについても詳しくお教えします！</p>`;

const CAMPAIGN_MESSAGE_TEMPLATE = `<p>●●月限定キャンペーンです！</p><br/>
<br/><br/><br/><br/><br/><p>●●●●コースを</p><br/>
<br/><br/><br/><p>通常●●●●円→●●●●円にてお試しいただけます。</p><br/>
<br/><br/><br/><p>このコースは（特徴やオススメポイント）です。</p><br/>
<br/><br/><br/><br/><br/><p>（お会計時・ご予約の際）にアプリを見ましたとお知らせください。</p><br/>
<br/><br/><br/>
<p>ご来店お待ちしております！</p>`;

const PROFILE_MESSAGE_TEMPLATE = `<p>プロフィールを登録すると、</p><br/>
<br/><br/><br/><p>あなたにぴったりな情報やお誕生日のスペシャルクーポンなど、</p><br/>
<br/><br/><br/><p>今よりも、もっとお得な情報が届きます!</p><br/>
<br/><br/><br/><br/><br/><p>登録をしてお得な最新情報・クーポンを受け取ろう!</p><br/>
<br/><br/><br/><br/><br/><p>プロフィールを登録する>></p>`;

@Component({
  components: { UIFormRow, quillEditor }
})
export default class NewEditContent extends Vue {
  @Prop({ type: String, required: true })
  shopId!: string;
  @PropSync("activeTab")
  syncedActiveTab!: number;
  @PropSync("eventId")
  syncedEventId?: number | null;
  @PropSync("couponId")
  syncedCouponId?: number | null;
  @PropSync("title")
  syncedTitle?: string | null;
  @PropSync("comment")
  syncedComment?: string;

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  editorOption = {
    modules: {
      toolbar: [
        [{ color: [] }, { background: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ size: ["small", false, "large", "huge"] }],
        [{ align: [] }],
        ["link", "image"],
        ["clean"]
      ]
    }
  };

  tabItems = [
    {
      title: "イベント・お知らせ配信",
      disabled: false
    },
    {
      title: "クーポン配信",
      disabled: false
    },
    {
      title: "新規作成",
      disabled: false
    }
  ];

  // OEM事業者ID
  businessId = 0 as number;

  get eventItems() {
    return ScheduleListGet.getItems;
  }

  get couponItems() {
    return CouponListGet.getItems;
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    let errorMessage = null as string | null;
    if (!ScheduleListGet.isSuccess) {
      await ScheduleListGet.get({
        id: Number(this.shopId)
      } as ScheduleGetRequest);
      if (!ScheduleListGet.isSuccess) {
        errorMessage = ScheduleListGet.getMessage;
      }
    }
    if (!(errorMessage || CouponListGet.isSuccess)) {
      await CouponListGet.get({ id: Number(this.shopId) } as CouponGetRequest);
      if (!CouponListGet.isSuccess) {
        errorMessage = CouponListGet.getMessage;
      }
    }
    if (errorMessage) {
      await Flash.setErrorNow({
        message: errorMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  async beforeDestroy() {
    await ScheduleListGet.clearResponse();
    await CouponListGet.clearResponse();
  }

  async mounted() {
    const storage = BusinessServiceStorage.getLocalStorage();
    this.businessId = storage.id ? storage.id : 0;
  }
  
  /**
   * 配信内容の新規作成時、お知らせボタンが押下された際のコールバック
   */
  infoClickCallback() {
    this.syncedComment = INFO_MESSAGE_TEMPLATE;
  }

  /**
   * 配信内容の新規作成時、再来店促進ボタンが押下された際のコールバック
   */
  visitClickCallback() {
    this.syncedComment = VISIT_MESSAGE_TEMPLATE;
  }

  /**
   * 配信内容の新規作成時、キャンペーンボタンが押下された際のコールバック
   */
  campaignClickCallback() {
    this.syncedComment = CAMPAIGN_MESSAGE_TEMPLATE;
  }

  /**
   * 配信内容の新規作成時、プロフィール登録促進ボタンが押下された際のコールバック
   */
  profileClickCallback() {
    this.syncedComment = PROFILE_MESSAGE_TEMPLATE;
  }
  
}
