import { Component, Mixins } from "vue-property-decorator";
import UIFormRow from "@/components/UIFormRow.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import NewEditContent from "@/views/push-notifications/NewEditContent.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import MixinNewEdit from "@/models/push-notifications/mixin-new-edit";
import ShopGet from "@/store/shop/get";
import { GetRequest as ShitenRequest } from "@/api/shiten-list/request";
import { GetRequest as ProfileRequest } from "@/api/profile-list/request";
import { GetRequest as TicketRequest } from "@/api/ticket-list/request";
import {
  GetDate,
  GetRequest as CountRequest
} from "@/api/push-user-count/request";
import { RegisterDate } from "@/api/push/request";
import {
  DetailItem,
  DetailProfile,
  DetailResult,
  DetailTicket
} from "@/api/push/response";
import { GetItem } from "@/api/profile-list/response";
import PushRegister from "@/store/push/register";
import ShitenListGet from "@/store/shiten-list/get";
import ProfileListGet from "@/store/profile-list/get";
import TicketListGet from "@/store/ticket-list/get";
import PushUserCountGet from "@/store/push-user-count/get";
import Flash, { ErrorAlert } from "@/store/common/flash";
import _ from "lodash";
import WindowOpen from "@/utils/window-open";

@Component({
  components: { UIFormRow, UIDatePicker, NewEditContent }
})
export default class NewEditCondition extends Mixins(
  RedirectWithAlert,
  MixinNewEdit
) {
  birthdayFromYear = null as string | null;
  birthdayFromMonth = null as string | null;
  birthdayFromDate = null as string | null;
  birthdayEndYear = null as string | null;
  birthdayEndMonth = null as string | null;
  birthdayEndDate = null as string | null;

  isProfiles = [] as number[];
  profileFromYears = {} as { [index: number]: string };
  profileFromMonth = {} as { [index: number]: string };
  profileFromDate = {} as { [index: number]: string };
  profileEndYears = {} as { [index: number]: string };
  profileEndMonth = {} as { [index: number]: string };
  profileEndDate = {} as { [index: number]: string };

  stampMin = {} as { [index: number]: number };
  stampMax = {} as { [index: number]: number };

  pointMin = {} as { [index: number]: number };
  pointMax = {} as { [index: number]: number };

  shopList = [] as number[];

  isCountUsers = false;

  get followShopItems() {
    return ShitenListGet.getItems;
  }

  get ticketBoughtUserItems() {
    return TicketListGet.getItems;
  }

  get pushUserCount() {
    if (PushUserCountGet.getCount) {
      return PushUserCountGet.getCount;
    } else {
      return 0;
    }
  }

  get optionItems() {
    return [
      { text: "を含む", value: 1 },
      { text: "を含まない", value: 2 },
      { text: "完全一致", value: 3 }
    ];
  }


  /**
   * createdライフサイクルフック
   */
  async created() {
    let getErrorMessage = null as string | null;
    const shiten = ShitenListGet.get(this.createShitenRequest());
    const profile = ProfileListGet.get(this.createProfileRequest());
    const ticket = TicketListGet.get(this.createTicketRequest());

    // 20210201 NEW_DEV-1346 cyber 董 end
    await Promise.all([shiten, profile, ticket, count]);
    if (!ShitenListGet.isSuccess) {
      getErrorMessage = ShitenListGet.getMessage;
    }
    if (!ProfileListGet.isSuccess) {
      getErrorMessage = ProfileListGet.getMessage;
    }
    if (!TicketListGet.isSuccess) {
      getErrorMessage = TicketListGet.getMessage;
    }
    if (!PushUserCountGet.isSuccess) {
      getErrorMessage = PushUserCountGet.getMessage;
    }
    if (getErrorMessage != null) {
      await Flash.setErrorNow({
        message: getErrorMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    if (this.isEdit && this.pushDetail) {
      this.syncBaseParams(this.pushDetail);
      this.syncSendContentParams(this.pushDetail);
      this.syncInputParams(this.pushDetail);
    }
    // 2021/02/01 NEW_DEV-1347 秦 Start
    else{
      this.inputParams.age = [];
    }
    // 2021/02/01 NEW_DEV-1347 秦 end

    await ShopGet.get();
    if (ShopGet.isSuccess) {
      this.shopList = ShopGet.getItems;
    }

    this.initProfile();
    // 20210201 NEW_DEV-1346 cyber 董 start
    const count = PushUserCountGet.get(this.createCountRequest(0));
  }

  /**
   * 配信人数を取得
   * 20210201 NEW_DEV-1348 cyber 董
   */
  async fetchPushUserCount() {
    this.isCountUsers = true;
    await this.callPushUserCountApi();
    if (PushUserCountGet.isSuccess) {
      const count1 = PushUserCountGet.getCount;
    }
    this.isCountUsers = false;
  }

  async beforeDestroy() {
    await PushRegister.clear();
    await ShitenListGet.clearResponse();
    await ProfileListGet.clearResponse();
    await TicketListGet.clearResponse();
    await PushUserCountGet.clearResponse();
  }

  async preview() {
    this.inputParams.isPreview = 1;
    const isSuccess = await this.callRegister();
    if (isSuccess) {
      WindowOpen.preview(PushRegister.getPreviewUrl);
    }
    this.inputParams.isPreview = 0;
  }

  private async callRegister(): Promise<boolean> {
    await PushRegister.register(this.createRegisterRequest());
    if (!PushRegister.isSuccess) {
      await Flash.setErrorNow({
        message: PushRegister.getMessage,
        showReloadButton: false
      });
      window.scrollTo(0, 0);
    }
    return PushRegister.isSuccess;
  }

  /**
   * 登録処理
   */
  async register() {
    // クーポン配信の場合は日付整合性チェック
    if (this.activeSubTab == 1) {     
      var errMsg =  await this.checkCouponDate();
      if (errMsg) {
        await Flash.setErrorNow({
          message: errMsg,
          showReloadButton: false
        });
        window.scrollTo(0, 0);
        return;
      }
    }
    
    await PushRegister.register(this.createRegisterRequest());
    if (PushRegister.isSuccess) {
      await Flash.clear();
      await this.redirectWithSuccessAlert(
        "プッシュメッセージを保存しました。",
        `/push-notifications/${this.shopId}/list?shopName=${this.shopName}`
      );
    } else {
      await Flash.setErrorNow({
        message: PushRegister.getMessage,
        showReloadButton: false
      });
      window.scrollTo(0, 0);
    }
  }

  getProfileItem(itemId: number) {
    return (
      ProfileListGet.getItems.find(p => p.itemId === itemId) || ({} as GetItem)
    );
  }

  // 20210201 NEW_DEV-1348 cyber 董 start
  private async callPushUserCountApi() {
    await PushUserCountGet.get(this.createCountRequest(1))
    if (!PushUserCountGet.isSuccess) {
      await Flash.setErrorNow({
        message: PushUserCountGet.getMessage,
        showReloadButton: false
      });
    }
  }
  // 20210201 NEW_DEV-1348 cyber 董 end

  private syncInputParams(pushDetail: DetailResult) {
    // 各フラグ
    this.inputParams.genderFlg = pushDetail.genderFlg;
    this.inputParams.ageFlg = pushDetail.ageFlg;
    this.inputParams.birthdayFlg = pushDetail.birthdayFlg;
    this.inputParams.profileFlg = pushDetail.profileFlg;
    this.inputParams.areaFlg = pushDetail.areaFlg;
    this.inputParams.stampFlg = pushDetail.stampFlg;
    this.inputParams.pointFlg = pushDetail.pointFlg;
    this.inputParams.followFlg = pushDetail.followFlg;
    this.inputParams.paidTicketFlg = pushDetail.paidTicketFlg;
    this.inputParams.ticketFlg = pushDetail.ticketFlg;
    // 各パラメータ
    this.inputParams.gender = _.cloneDeep(pushDetail.gender);
    this.inputParams.age = _.cloneDeep(pushDetail.age);
    this.inputParams.profileUndefined = pushDetail.profileUndefined;
    if (pushDetail.profile) {
      this.inputParams.profile = _.cloneDeep(pushDetail.profile);
    }
    this.inputParams.area = _.cloneDeep(pushDetail.area);
    if (pushDetail.stamp) {
      this.inputParams.stamp = _.cloneDeep(pushDetail.stamp);
      this.stampMin[pushDetail.stamp.type] = pushDetail.stamp.min;
      this.stampMax[pushDetail.stamp.type] = pushDetail.stamp.max;
    }
    if (pushDetail.point) {
      this.inputParams.point = _.cloneDeep(pushDetail.point);
      this.pointMin[pushDetail.point.type] = pushDetail.point.min;
      this.pointMax[pushDetail.point.type] = pushDetail.point.max;
    }
    this.inputParams.favoriteShopIds = _.cloneDeep(pushDetail.favoriteShopIds);
    if (pushDetail.ticket) {
      this.inputParams.ticket = _.cloneDeep(pushDetail.ticket);
    } else {
      this.inputParams.ticket = {
        ids: [],
        type: null,
        value: null
      } as DetailTicket;
    }
    // 誕生日
    if (pushDetail.birthdayFrom) {
      if (pushDetail.birthdayFrom.length > 4) {
        this.birthdayFromYear = pushDetail.birthdayFrom.slice(0, 4);
        this.birthdayFromMonth = pushDetail.birthdayFrom.slice(4, 6);
        this.birthdayFromDate = pushDetail.birthdayFrom.slice(6, 8);
      } else {
        this.birthdayFromMonth = pushDetail.birthdayFrom.slice(0, 2);
        this.birthdayFromDate = pushDetail.birthdayFrom.slice(2, 4);
      }
      this.inputParams.birthdayFrom = pushDetail.birthdayFrom;
    }
    if (pushDetail.birthdayEnd) {
      if (pushDetail.birthdayEnd.length > 4) {
        this.birthdayEndYear = pushDetail.birthdayEnd.slice(0, 4);
        this.birthdayEndMonth = pushDetail.birthdayEnd.slice(4, 6);
        this.birthdayEndDate = pushDetail.birthdayEnd.slice(6, 8);
      } else {
        this.birthdayEndMonth = pushDetail.birthdayEnd.slice(0, 2);
        this.birthdayEndDate = pushDetail.birthdayEnd.slice(2, 4);
      }
      this.inputParams.birthdayEnd = pushDetail.birthdayEnd;
    }
  }

  private initProfile() {
    if (!this.inputParams.profile || this.inputParams.profile.length <= 0) {
      this.inputParams.profile = [];
      for (let item of ProfileListGet.getItems) {
        const p = {
          koumokuTypeId: item.koumokuTypeId,
          item: [
            {
              itemId: item.itemId,
              value: [] as string[],
              detailMei: item.detail[0].detailMei
            } as DetailItem
          ]
        } as DetailProfile;
        this.inputParams.profile.push(p);
      }
    }
    for (let i = 0; i < this.inputParams.profile.length; i++) {
      this.isProfiles[i] = this.isEdit ? 1 : 0;
      if (
        this.inputParams.profile[i].koumokuTypeId === 5 &&
        this.inputParams.profile[i].item
      ) {
        // 日付の場合
        for (let item of this.inputParams.profile[i].item!) {
          if (item.value && item.itemId) {
            switch (item.value.length) {
              case 6:
                this.profileFromYears[item.itemId] = item.value[0];
                this.profileFromMonth[item.itemId] = item.value[1];
                this.profileFromDate[item.itemId] = item.value[2];
                this.profileEndYears[item.itemId] = item.value[3];
                this.profileEndMonth[item.itemId] = item.value[4];
                this.profileEndDate[item.itemId] = item.value[5];
                break;
             case 4:
               if (this.showProfileBlock(item.detailMei, 0) && this.showProfileBlock(item.detailMei, 1)) { // 年月
                 this.profileFromYears[item.itemId] = item.value[0];
                 this.profileEndYears[item.itemId] = item.value[2];
                 this.profileFromMonth[item.itemId] = item.value[1];
                 this.profileEndMonth[item.itemId] = item.value[3];
               } else if (this.showProfileBlock(item.detailMei, 0) && this.showProfileBlock(item.detailMei, 2)) { // 年日
                 this.profileFromYears[item.itemId] = item.value[0];
                 this.profileEndYears[item.itemId] = item.value[2];
                 this.profileFromDate[item.itemId] = item.value[1];
                 this.profileEndDate[item.itemId] = item.value[3];
               } else if (this.showProfileBlock(item.detailMei, 1) && this.showProfileBlock(item.detailMei, 2)) { // 月日
                 this.profileFromMonth[item.itemId] = item.value[0];
                 this.profileEndMonth[item.itemId] = item.value[2];
                 this.profileFromDate[item.itemId] = item.value[1];
                 this.profileEndDate[item.itemId] = item.value[3];
               }
               break;
             case 2:
               if (this.showProfileBlock(item.detailMei, 0)) { // 年
                 this.profileFromYears[item.itemId] = item.value[0];
                 this.profileEndYears[item.itemId] = item.value[1];
             } else if (this.showProfileBlock(item.detailMei, 1)) { // 月
                 this.profileFromMonth[item.itemId] = item.value[0];
                 this.profileEndMonth[item.itemId] = item.value[1];
             } else if (this.showProfileBlock(item.detailMei,2)) { // 日
                 this.profileFromDate[item.itemId] = item.value[0];
                 this.profileEndDate[item.itemId] = item.value[1];
               }
               break;
            }
          }
        }
      }
    }
  }

  private createRegisterRequest() {
    const request = this.createRegisterCommonRequest();
    request.pushType = 2;
    switch (this.activeSubTab) {
      case 0:
        request.contentsType = 1;
        request.contentsId = this.eventId;
        break;
      case 1:
        request.contentsType = 2;
        request.contentsId = this.couponId;
        break;
      case 2:
        request.contentsType = 5;
        request.title = this.inputParams.title;
        request.comment = this.inputParams.comment;
        break;
    }
    // 2021/02/01 NEW_DEV-1347 秦 Start
    request.genderFlg = this.inputParams.genderFlg;
    request.ageFlg = this.inputParams.ageFlg;
    // 2021/02/01 NEW_DEV-1347 秦 End
    if (this.inputParams.genderFlg === 1) {
      request.gender = _.cloneDeep(this.inputParams.gender);
    }
    if (this.inputParams.ageFlg === 1) {
      request.age = _.cloneDeep(this.inputParams.age);
    }
    if (this.inputParams.birthdayFlg === 1) {
      // 20210201 NEW_DEV-1346 cyber 董 start
      request.birthdayFlg = this.inputParams.birthdayFlg;
      let from = null;
      let to = null;
      if(this.birthdayFromMonth != null && this.birthdayFromDate != null){
        if (this.birthdayFromYear != null) {
          from = this.birthdayFromYear + ("0" + this.birthdayFromMonth).slice(-2) + ("0" + this.birthdayFromDate).slice(-2);
        } else {
          from = ("0" + this.birthdayFromMonth).slice(-2) + ("0" + this.birthdayFromDate).slice(-2);
        }
      }
      if(this.birthdayEndMonth != null && this.birthdayEndDate != null){
        if (this.birthdayEndYear != null) {
           to = this.birthdayEndYear + ("0" + this.birthdayEndMonth).slice(-2) + ("0" + this.birthdayEndDate).slice(-2);
        } else {
           to = ("0" + this.birthdayEndMonth).slice(-2) + ("0" + this.birthdayEndDate).slice(-2);
        }
      }

      if (from != null && to != null) {
        request.birthday = {
          from: from,
          to: to
        } as RegisterDate;
      }
    }
    if (this.inputParams.profileFlg === 1) {
      request.profile = [];
      if (this.inputParams.profile) {
        for (let i = 0; i < this.inputParams.profile.length; i++) {
          if (!this.isProfiles[i]) {
            continue;
          }
          const p = _.cloneDeep(this.inputParams.profile[i]);
          if (p.koumokuTypeId === 5) {
            // 日付の場合
            let date = this.dateFormat(p.item[0].itemId, p.item[0].detailMei, false);
            if (p.item && p.item.length > 0) {
              p.item[0].value = date;
            }
          }
          request.profile.push(p);
        }
      }
    }
    if (this.inputParams.profileUndefined === 1) {
      request.profileUndefined = this.inputParams.profileUndefined;
    }
    if (this.inputParams.areaFlg === 1) {
      request.area = _.cloneDeep(this.inputParams.area);
    }
    if (this.inputParams.stampFlg === 1) {
      request.stamp = _.cloneDeep(this.inputParams.stamp);
      if (request.stamp && request.stamp.type) {
        request.stamp.min = this.stampMin[request.stamp.type];
        request.stamp.max = this.stampMax[request.stamp.type];
      }
    }
    if (this.inputParams.pointFlg === 1 && this.inputParams.point) {
      request.point = _.cloneDeep(this.inputParams.point);
      if (request.point && request.point.type) {
        request.point.min = this.pointMin[request.point.type];
        request.point.max = this.pointMax[request.point.type];
      }
    }
    if (
      this.inputParams.favoriteShopIds &&
      this.inputParams.favoriteShopIds.length > 0
    ) {
      request.favoriteShopIds = _.cloneDeep(this.inputParams.favoriteShopIds);
    }
    if (this.inputParams.ticketFlg === 1) {
      request.ticket = _.cloneDeep(this.inputParams.ticket);
    }
    if (this.inputParams.isPreview) {
      request.isPreview = this.inputParams.isPreview;
    }else{
      request.isPreview = 0;
    }
    return request;
  }

  private createShitenRequest() {
    return { id: Number(this.shopId) } as ShitenRequest;
  }

  private createProfileRequest() {
    return { id: Number(this.shopId) } as ProfileRequest;
  }

  private createTicketRequest() {
    return { id: Number(this.shopId) } as TicketRequest;
  }

  // 20210201 NEW_DEV-1346 cyber 董 start
  private createCountRequest(ajaxFlag : Number) {
    // NEW_DEV-1437 cyber start
    const request = {
      shopId: Number(this.shopId),
      pushType: 2,
      ignoreFollowFlg: Number(this.ignoreFollowFlg)
    } as CountRequest;
    // NEW_DEV-1437 cyber end

    // 20210203 NEW_DEV-1348 cyber 董 Start
    request.genderFlg = this.inputParams.genderFlg;
    request.ageFlg = this.inputParams.ageFlg;
    // 20210203 NEW_DEV-1348 cyber 董 End
    if (this.inputParams.genderFlg === 1) {
      request.gender = _.cloneDeep(this.inputParams.gender);
    }
    if (this.inputParams.ageFlg === 1) {
      request.age = _.cloneDeep(this.inputParams.age);
    }
    if (this.inputParams.birthdayFlg === 1) {
      request.birthdayFlg = this.inputParams.birthdayFlg;
      if(ajaxFlag === 1){
        let from = null;
        let to = null;
        if(this.birthdayFromMonth != null && this.birthdayFromDate != null){
          if (this.birthdayFromYear != null) {
            from = this.birthdayFromYear + ("0" + this.birthdayFromMonth).slice(-2) + ("0" + this.birthdayFromDate).slice(-2);
          } else {
            from = ("0" + this.birthdayFromMonth).slice(-2) + ("0" + this.birthdayFromDate).slice(-2);
          }
        }
        if(this.birthdayEndMonth != null && this.birthdayEndDate != null){
          if (this.birthdayEndYear != null) {
             to = this.birthdayEndYear + ("0" + this.birthdayEndMonth).slice(-2) + ("0" + this.birthdayEndDate).slice(-2);
          } else {
             to = ("0" + this.birthdayEndMonth).slice(-2) + ("0" + this.birthdayEndDate).slice(-2);
          }
        }

        if (from != null && to != null) {
          request.birthday = {
            from: from,
            to: to
          } as GetDate;
        }

      }else{
        request.birthday = {
          from: this.inputParams.birthdayFrom,
          to: this.inputParams.birthdayEnd
        } as GetDate;
      }
    }
    // 20210201 NEW_DEV-1346 cyber 董 end
    if (this.inputParams.profileFlg === 1) {
      // 20210204 NEW_DEV-1348 cyber start
      request.profile = [];
      if (this.inputParams.profile) {
        for (let i = 0; i < this.inputParams.profile.length; i++) {
          if (!this.isProfiles[i]) {
            continue;
          }
          const p = _.cloneDeep(this.inputParams.profile[i]);
          if (p.koumokuTypeId === 5) {
            // 日付の場合
            let from = null;
            let to = null;
            let date = this.dateFormat(p.item[0].itemId, p.item[0].detailMei, true);
            if (p.item && p.item.length > 0) {
              p.item[0].value = date;
            }
          }
          request.profile.push(p);
        }
      }
      // 20210204 NEW_DEV-1348 cyber end
    }
    if (this.inputParams.profileUndefined === 1) {
      request.profileUndefined = _.cloneDeep(this.inputParams.profileUndefined);
    }
    // 20210202 NEW_DEV-1348 cyber 董 start
    if (this.inputParams.areaFlg === 1) {
      request.area = _.cloneDeep(this.inputParams.area);
    }
    if (this.inputParams.stampFlg === 1) {
      request.stamp = _.cloneDeep(this.inputParams.stamp);
      if (request.stamp && request.stamp.type) {
        request.stamp.min = this.stampMin[request.stamp.type];
        request.stamp.max = this.stampMax[request.stamp.type];
      }
    }
    if (this.inputParams.pointFlg === 1) {
      request.point = _.cloneDeep(this.inputParams.point);
      if (request.point && request.point.type) {
        request.point.min = this.pointMin[request.point.type];
        request.point.max = this.pointMax[request.point.type];
      }
    }
    // 20210202 NEW_DEV-1348 cyber 董 end
    if (
      this.inputParams.favoriteShopIds &&
      this.inputParams.favoriteShopIds.length > 0
    ) {
      request.favoriteShopIds = _.cloneDeep(this.inputParams.favoriteShopIds);
    }
    if (this.inputParams.ticketFlg === 1) {
      request.ticket = _.cloneDeep(this.inputParams.ticket);
    }
    return request;
  }

  private dateFormat(itemId : Number, detailMei: String, flg = true) {
    let result = [];
    if(this.profileFromYears[itemId]){
      result.push(this.profileFromYears[itemId]);
    } else if (this.showProfileBlock(detailMei, 0)) {
      result.push('');
    }
    if(this.profileFromMonth[itemId]){
      result.push(("0" + this.profileFromMonth[itemId]).slice(-2));
    } else if (this.showProfileBlock(detailMei, 1)) {
      result.push('');
    }
    if (this.profileFromDate[itemId]){
      result.push(("0" + this.profileFromDate[itemId]).slice(-2));
    } else if (this.showProfileBlock(detailMei, 2)) {
      result.push('');
    }

    if(this.profileEndYears[itemId]){
      result.push(this.profileEndYears[itemId]);
    } else if (this.showProfileBlock(detailMei, 0)) {
      result.push('');
    }
    if(this.profileEndMonth[itemId]){
      result.push(("0" + this.profileEndMonth[itemId]).slice(-2));
    } else if (this.showProfileBlock(detailMei, 1)) {
      result.push('');
    }
    if(this.profileEndDate[itemId]){
      result.push(("0" + this.profileEndDate[itemId]).slice(-2));
    } else if (this.showProfileBlock(detailMei, 2)) {
      result.push('');
    }

    return result;
  }

  public showProfileBlock (detailMei: String, number : Number) {
    let splitTmp = detailMei.split(',');
    if (splitTmp[number] == '1') {
        return true;
    }

    return false;
  }
}
