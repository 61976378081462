import { render, staticRenderFns } from "./NewEditTarget.vue?vue&type=template&id=f86744fe&"
import script from "../../models/push-notifications/new-edit-target.ts?vue&type=script&lang=ts&"
export * from "../../models/push-notifications/new-edit-target.ts?vue&type=script&lang=ts&"
import style0 from "./NewEditTarget.vue?vue&type=style&index=0&id=f86744fe&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports