import {
  getModule,
  Module,
  VuexModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import { SessionStorage } from "@/store/session-storage";
import { SearchResponse, SearchItem } from "@/api/user/response";
import { SearchListRequest, SearchRequest } from "@/api/user/request";
import * as UserAPI from "@/api/user";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "user-push/list";

/**
 * ユーザ一覧のデコレーター
 */
export class ListItem {
  constructor(private _item: SearchItem) {}
  /** 店舗ID */
  get shopId() {
    return this._item.shopId;
  }
  /** 店舗名 */
  get shopName() {
    return this._item.shopName;
  }
  /** ユーザーID */
  get userId() {
    return this._item.userId;
  }
  /** ユーザー名 */
  get userName() {
    return this._item.userName;
  }
  /** 誕生日 */
  get birthday() {
    return this._item.birthday;
  }
  /** 性別 */
  get gender() {
    switch (this._item.gender) {
      case 1:
        return "男性";
      case 2:
        return "女性";
      default:
        return "不明";
    }
  }
  /** 追加・編集権限の有無 */
  get writeFlg() {
    return this._item.writeFlg;
  }
  /** 削除権限の有無 */
  get deleteFlg() {
    return this._item.deleteFlg;
  }
}

/**
 * ユーザー一覧・検索API（/search-user）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class List extends VuexModule {
  // state
  searchResponse: SearchResponse = {} as SearchResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.searchResponse);
  }

  get getMessage() {
    return this.searchResponse.message;
  }

  get getItems() {
    if (this.searchResponse.results) {
      return (this.searchResponse.results.items || []).map(
        i => new ListItem(i)
      );
    } else {
      return [];
    }
  }

  // MutationActions
  @MutationAction
  async search(searchListRequest: SearchListRequest) {
    const searchResponse = await UserAPI.list(searchListRequest);
    SessionStorage.setObject(MODULE_NAME, searchListRequest);
    return {
      searchResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      searchResponse: {} as SearchResponse
    };
  }
}

export default getModule(List);
